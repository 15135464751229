/* src/App.css */
body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .App {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
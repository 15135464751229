/* src/LandingPage.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
  }
  .video-container {
    position: fixed;
    width: 100%;
    /* height: 50%; */
    height: 100%;
  }

  /* .video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    overflow: hidden;
    z-index: -1;
  }
   */
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .video-background video {
    width: 100%;
    height: 100%;
    /* height: 80%; */
    object-fit: cover;
  }
  

  .content-overlay {
    /* margin-top: 25%; */
    position: relative;
    z-index: 1;
  }
  
  .container {
    padding: 2rem;
    text-align: center;
  }
  
  header {
    /* background-color: rgba(51, 51, 51, 0.8); */
    background-color: rgba(51, 51, 51, 0.3);
    color: #fff;
    padding: 1rem 0;
  }
  
  .category-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    margin-top: 2rem;
  }
  
  .category-item {
    /* background-color: rgba(255, 255, 255, 0.8); */
    background-color: rgba(255, 255, 255, 0.3);
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
  }
  
  .category-item i {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .footer {
    background-color: rgba(51, 51, 51, 0.8);
    color: #fff;
    text-align: center;
    padding: 1rem 0;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .social-icon {
    color: #fff;
    text-decoration: none;
  }
  
  .social-icon:hover {
    color: #ddd;
  }
  

  
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 80%;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}